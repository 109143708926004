<template>
    <div class="downloadstatus"  >
        <div class="downloadstatus-icon">
            <div class="icon-content">
                <v-icon class="icon" size="32">mdi-download-circle</v-icon>
            </div>
        </div>
        <div class="downloadstatus-status">
            <span v-if="percentCompleted > 0">{{ downloadEvent.file }} </span>
            <span v-else>Relatório sendo gerado... </span>
            <v-progress-linear v-if="percentCompleted > 0" :value="percentCompleted"  height="25" class="download-progress">
                <span class="font-weight-bold white--text"
                    >{{ percentCompleted }} %</span
                >
            </v-progress-linear>
        </div>
        <!-- <div class="downloadstatus-value">
            <span>{{ valor || '' }}</span>
        </div> -->
    </div>
</template>

<script>
export default {
    data () {
        return {
            percentCompleted : 0,
        }
    },
    props: ["downloadEvent"],
    async mounted() {
        console.log('mount')


        const response = await this.$http().post("/gerencial/exportarVb",
            {...this.downloadEvent},
            {
                responseType: "blob",
                onDownloadProgress: (progressEvent) => {
                    let percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.currentTarget.response.size
                    );
                    this.percentCompleted = percentCompleted;
                    console.log('sddsd',this.percentCompleted,progressEvent,progressEvent.currentTarget.response.size)

                }
            }
        )
        console.log(response)
        // .then((response) => {
            // let mainfilename = response.headers["content-disposition"]
            //     .split("filename=")[1]
            //     .split(";")[0].replace(/^"(.*)"$/, '$1');
            // console.log(mainfilename.replace(/^"(.*)"$/, '$1'))
            this.percentCompleted = 100
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", this.downloadEvent.file);
            document.body.appendChild(link);
            link.click();
            
        // });

        // var response = await this.$http({
        //         url:"/gerencial/exportarVb",
        //         method:'GET',
        //         responseType: 'stream'
        // })    
        // const totalLength = Headers['content-length']
        console.log('Starting download')
        // const progressBar = new ProgressBar('-> downloading [:bar] :percent :etas',{
        //     with: 40,
        //     complete: '=',
        //     incomplete: ' ',
        //     renderThrotter: 1,
        //     total: parseInt(totalLength)
        // })

        // data.on('data', (chunk) => this.index = chunk.length)
        // data.pipe(writer)

        //    {
        //     data: this.downloadEvent,
        //     onDownloadProgress: progressEvent => {
        //         const total = parseFloat(progressEvent.currentTarget.responseHeaders['Content-Length'])
        //         const current = progressEvent.currentTarget.response.length
            
        //         this.index = Math.floor(current / total * 100)
        //         //console.log('completed: ', percentCompleted)
        //         // content.commit('updateDownload',payload)
        //     }
        //     })
        //     .then(res => {
        //     console.log("All DONE: ", res.headers)
        //     return res.data
        //     })
    }
}
</script>

<style>
.download-progress {
    border-radius: 30px;
}
.downloadstatus.active::before {
    background: var(--v-primary-base);
}
.downloadstatus.active {
    background: var(--v-primary-base);
    color: #fff;
}
.downloadstatus {
    margin: 10px 10px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
    background: #ede8e8;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
    transition: all 0.4s;
    /* cursor: pointer; */
    padding: 10px 2px;
}

.downloadstatus.active .downloadstatus-icon {
    background: white;
}
.downloadstatus.active .icon {
    color: var(--v-primary-base);
}
.downloadstatus .icon {
    color: white;
}
.downloadstatus::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background: #eaeaea;
    transition: all 0.4s;
}
.downloadstatus:hover::before {
    background: var(--v-primary-base);
}
.downloadstatus:hover {
    filter: grayscale(0);
}
.downloadstatus-icon {
    background-color: var(--v-primary-base);
    border-radius: 50%;
    display: flex;
    position: relative;
    padding: 6px;
    /* margin-top: 6px; */
    margin: 4px;
    box-shadow: 0 0 10px #0002;
}
.downloadstatus-status {
    margin-left: 10px;
    font-weight: 500;
    margin-bottom: 0;
    font-size: 11px;
    display: flex;
    flex-direction: column;
    width: 190px;
}
.downloadstatus-separador {
    width: 25px;
    height: 1px;
    background: #e0e0e0;
    margin-top: 5px;
}
.downloadstatus-value {
    font-size: 1rem;
    font-weight: 500;
    margin-left: 5px;
    color: var(--v-primary-base);
}
.downloadstatus.active .downloadstatus-value {
    color: white;
}
</style>
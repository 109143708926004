<template>
	<div>
		<v-card class="mt-2 pt-4 px-2" elevation="1">
			<v-form ref="formFiltro">
				<v-row no-gutters>
					<v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
						<v-text-field
							label="Mês"
							v-model="mes"
							:rules="regra.mes"
							required
							outlined
							dense
							type="month"
						></v-text-field>
					</v-col>
					<v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
						<v-autocomplete
							label="Filial"
							v-model="id_filial"
							:items="lista.filial"
							multiple
							outlined
							dense
						>
							<template v-slot:selection="{ item, index }">
								<div
									v-if="id_filial.length === 1 && index === 0"
									style="
						width: 70% !important;
						white-space: nowrap !important;
						text-overflow: ellipsis !important;
						overflow: hidden;
					"
								>
									{{ item.text }}
								</div>
								<span
									v-if="id_filial.length > 1 && index === 0"
									class="grey--text caption mr-1"
									>{{ id_filial.length }} selecionados</span
								>
							</template>
						</v-autocomplete>
					</v-col>
					<v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
						<v-select
							label="Bandeira"
							v-model="bandeira"
							:items="lista.bandeira"
							multiple
							outlined
							dense
						>
							<template v-slot:selection="{ item, index }">
								<span
									v-if="bandeira.length === 1 && index === 0"
									>{{ item.text }}</span
								>
								<span
									v-if="bandeira.length > 1 && index === 0"
									class="grey--text caption mr-1"
									>{{ bandeira.length }} selecionados</span
								>
							</template>
						</v-select>
					</v-col>
					<v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
						<v-autocomplete
							label="Fornecedor"
							v-model="id_fornecedor"
							:items="lista.fornecedor"
							multiple
							outlined
							dense
						>
							<template v-slot:selection="{ item, index }">
								<div
									v-if="
										id_fornecedor.length === 1 && index === 0
									"
									style="
						width: 70% !important;
						white-space: nowrap !important;
						text-overflow: ellipsis !important;
						overflow: hidden;
					"
								>
									{{ item.text }}
								</div>
								<span
									v-if="
										id_fornecedor.length > 1 && index === 0
									"
									class="grey--text caption mr-1"
									>{{
										id_fornecedor.length
									}}
									selecionados</span
								>
							</template>
						</v-autocomplete>
					</v-col>
					<v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
						<v-autocomplete
							label="Espaço"
							v-model="id_espaco"
							:items="lista.espaco"
							multiple
							outlined
							dense
						>
							<template v-slot:selection="{ item, index }">
								<div
									v-if="id_espaco.length === 1 && index === 0"
									style="
						width: 70% !important;
						white-space: nowrap !important;
						text-overflow: ellipsis !important;
						overflow: hidden;
					"
								>
									{{ item.text }}
								</div>
								<span
									v-if="id_espaco.length > 1 && index === 0"
									class="grey--text caption mr-1"
									>{{ id_espaco.length }} selecionados</span
								>
							</template>
						</v-autocomplete>
					</v-col>
					<v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
						<v-autocomplete
							label="Espaço Macro"
							v-model="espaco_macro"
							:items="lista.espaco_macro"
							multiple
							outlined
							dense
							
						>
							<template v-slot:selection="{ item, index }">
								<div
									v-if="
										espaco_macro.length === 1 && index === 0
									"
									style="
						width: 70% !important;
						white-space: nowrap !important;
						text-overflow: ellipsis !important;
						overflow: hidden;
					"
								>
									{{ item.text }}
								</div>
								<span
									v-if="espaco_macro.length > 1 && index === 0"
									class="grey--text caption mr-1"
									>{{ espaco_macro.length }} selecionados</span
								>
							</template>
						</v-autocomplete>
					</v-col>
					<v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
						<v-select
							label="Cluster"
							:items="lista.cluster"
							v-model="id_cluster"
							multiple
							outlined
							dense
						>
							<template v-slot:selection="{ item, index }">
								<span
									v-if="id_cluster.length === 1 && index === 0"
									>{{ item.text }}</span
								>
								<span
									v-if="id_cluster.length > 1 && index === 0"
									class="grey--text caption mr-1"
									>{{ id_cluster.length }} selecionados</span
								>
							</template>
						</v-select>
					</v-col>
					<v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
						<v-autocomplete
							label="Região"
							v-model="regiao"
							:items="lista.regiao"
							multiple
							outlined
							dense
						>
							<template v-slot:selection="{ item, index }">
								<div
									v-if="regiao.length === 1 && index === 0"
									style="
						width: 70% !important;
						white-space: nowrap !important;
						text-overflow: ellipsis !important;
						overflow: hidden;
					"
								>
									{{ item.text }}
								</div>
								<span
									v-if="regiao.length > 1 && index === 0"
									class="grey--text caption mr-1"
									>{{ regiao.length }} selecionados</span
								>
							</template>
						</v-autocomplete>
					</v-col>
					<v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
						<v-select
							label="Perfil"
							:items="lista.perfil"
							v-model="perfil"
							multiple
							outlined
							dense
						>
							<template v-slot:selection="{ item, index }">
								<span
									v-if="perfil.length === 1 && index === 0"
									>{{ item.text }}</span
								>
								<span
									v-if="perfil.length > 1 && index === 0"
									class="grey--text caption mr-1"
									>{{ perfil.length }} selecionados</span
								>
							</template>
						</v-select>
					</v-col>
					<v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
						<v-select
							label="Tamanho"
							:items="lista.tamanho"
							v-model="tamanho"
							multiple
							outlined
							dense
						>
							<template v-slot:selection="{ item, index }">
								<span
									v-if="tamanho.length === 1 && index === 0"
									>{{ item.text }}</span
								>
								<span
									v-if="tamanho.length > 1 && index === 0"
									class="grey--text caption mr-1"
									>{{ tamanho.length }} selecionados</span
								>
							</template>
						</v-select>
					</v-col>
					<v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
						<v-autocomplete
							label="Grupo"
							v-model="grupo"
							:items="lista.grupo"
							multiple
							outlined
							dense
						>
							<template v-slot:selection="{ item, index }">
								<div
									v-if="grupo.length === 1 && index === 0"
									style="
						width: 70% !important;
						white-space: nowrap !important;
						text-overflow: ellipsis !important;
						overflow: hidden;
					"
								>
									{{ item.text }}
								</div>
								<span
									v-if="grupo.length > 1 && index === 0"
									class="grey--text caption mr-1"
									>{{ grupo.length }} selecionados</span
								>
							</template>
						</v-autocomplete>
					</v-col>
					<v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
						<v-autocomplete
							label="Subgrupo"
							v-model="subgrupo"
							:items="lista.subgrupo"
							multiple
							outlined
							dense
						>
							<template v-slot:selection="{ item, index }">
								<div
									v-if="subgrupo.length === 1 && index === 0"
									style="
						width: 70% !important;
						white-space: nowrap !important;
						text-overflow: ellipsis !important;
						overflow: hidden;
					"
								>
									{{ item.text }}
								</div>
								<span
									v-if="subgrupo.length > 1 && index === 0"
									class="grey--text caption mr-1"
									>{{ subgrupo.length }} selecionados</span
								>
							</template>
						</v-autocomplete>
					</v-col>
					<v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
						<v-autocomplete
							label="Categoria"
							v-model="categoria"
							:items="lista.categoria"
							multiple
							outlined
							dense
						>
							<template v-slot:selection="{ item, index }">
								<div
									v-if="categoria.length === 1 && index === 0"
									style="
						width: 70% !important;
						white-space: nowrap !important;
						text-overflow: ellipsis !important;
						overflow: hidden;
					"
								>
									{{ item.text }}
								</div>
								<span
									v-if="categoria.length > 1 && index === 0"
									class="grey--text caption mr-1"
									>{{ categoria.length }} selecionados</span
								>
							</template>
						</v-autocomplete>
					</v-col>
					<v-col cols="12" sm="6" md="4" lg="3" xl="3" class="px-2">
						<v-autocomplete
							label="Subcategoria"
							v-model="subcategoria"
							:items="lista.subcategoria"
							multiple
							outlined
							dense
						>
							<template v-slot:selection="{ item, index }">
								<div
									v-if="
										subcategoria.length === 1 && index === 0
									"
									style="
						width: 70% !important;
						white-space: nowrap !important;
						text-overflow: ellipsis !important;
						overflow: hidden;
					"
								>
									{{ item.text }}
								</div>
								<span
									v-if="subcategoria.length > 1 && index === 0"
									class="grey--text caption mr-1"
									>{{ subcategoria.length }} selecionados</span
								>
							</template>
						</v-autocomplete>
					</v-col>

					<v-col
						cols="12"
						sm="6"
						md="4"
						lg="3"
						xl="3"
						class="px-2 ml-auto pb-2"
						align="end"
					>
						<v-btn class="ml-2 mb-1" color="primary" @click="limpar()">
							<v-icon left>mdi-filter-off</v-icon> Limpar
						</v-btn>
						<v-btn class="ml-2 mb-1" color="primary" @click="filtrar()">
							<v-icon left>mdi-filter</v-icon> Filtrar
						</v-btn>
					</v-col>

					<!-- <v-col cols="12" class="px-2 d-flex justify-start">
						<v-checkbox
							class="mt-0"
							v-model="vbZerado"
							label="VB zerado "
							@change="ajusteVbZerado"
						></v-checkbox>
						<v-checkbox
							class="ml-3 mt-0"
							v-model="cuboMovelDermo"
							label="Cubo+Movel Dermo "
							@change="ajusteCuboMovelDermo"
						></v-checkbox>
						<v-checkbox
							class="ml-3 mt-0"
							v-model="apenasCampanhas"
							label="Apenas Campanhas"
							@change="ajusteApenasCampanhas"
						></v-checkbox>
					</v-col> -->
				</v-row>
			</v-form>
		</v-card>
		<v-row  class="w-full">
			<v-col md="3">
				<div @click="ajusteVbZerado">
					<Indicador
						titulo="VB zerado"
						:valor="vbZerado ? vbZeradoQuantidade : ''"
						icone="mdi-numeric-0-circle"
						color="success"
						:class="{
							active: vbZerado,
						}"
					/>
				</div>
			</v-col>
			<v-col md="3">
				<div @click="ajusteCuboMovelDermo">
					<Indicador
						titulo="Cubo + Movel Dermo"
						:valor="cuboMovelDermo ? cuboMovelDermoQuantidade : ''"
						icone="mdi-cube"
						color="warning"
						:class="{
							active: cuboMovelDermo,
						}"
					/>
				</div>
			</v-col>
			<v-col md="3">
				<div @click="ajusteApenasCampanhas">
					<Indicador
						titulo="Apenas Campanhas"
						:valor="apenasCampanhas ? apenasCampanhasQuantidade : ''"
						icone="mdi-shopping"
						color="error"
						:class="{
							active: apenasCampanhas,
						}"
					/>
				</div>
			</v-col>
			<v-col md="3">
				<div @click="busqueCuboMovelDermoCampanha">
					<Indicador
						titulo="Campanhas + Cubo + Movel Dermo"
						:valor="cuboMovelDermoCampanha ? cuboMovelDermoCampanhaQuantidade : ''"
						icone="mdi-animation"
						color="error"
						:class="{
							active: cuboMovelDermoCampanha,
						}"
					/>
				</div>
			</v-col>
		</v-row>
	</div>		
</template>

<script>
import useFiltros from "../../../hooks/useFiltros";
import Indicador from "./_components/Indicador.vue";
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
export default {
    setup() {
        const { isLoading, isError, isFetching, lista, error, refetch } =
            useFiltros("/gerencial/filtros");

        return { isLoading, isError, isFetching, lista, error, refetch };
    },
	data() {
		return {
			// lista: {},
			// cuboMovelDermo: false,
			//filtro: {},
			regra: {
				mes: [(v) => !!v || "O campo mes é obrigatório"],
			},
		};
	},
    computed: {
		...mapFields('relatorio_vb',[
			'filtro.mes',
			'filtro.vbZerado',
			'filtro.apenasCampanhas',
			'filtro.cuboMovelDermoCampanha',
			'filtro.bandeira',
			'filtro.id_espaco',
			'filtro.espaco_macro',
			'filtro.id_fornecedor',
			'filtro.id_cluster',
			'filtro.id_filial',
			'filtro.regiao',
			'filtro.perfil',
			'filtro.tamanho',
			'filtro.grupo',
			'filtro.subgrupo',
			'filtro.categoria',
			'filtro.subcategoria',
			'filtro.cuboMovelDermo',
			'filtro.vbZeradoQuantidade',
            'filtro.cuboMovelDermoQuantidade',
            'filtro.apenasCampanhasQuantidade',
			'filtro.cuboMovelDermoCampanhaQuantidade'
		]),		
        overlay() {
            return Object.keys(this.lista).length === 0 && this.isFetching;
        },
    },	
	components: {
		Indicador
	},
	methods: {
		...mapActions('relatorio_vb',['iniciaFiltro','busqueDados']),
		busqueCuboMovelDermoCampanha() {
			if (!this.validate()) return;
			this.vbZerado = false
			this.cuboMovelDermo = false
			this.apenasCampanhas = false
			this.cuboMovelDermoCampanha = true
			// this.busqueDados();			
		},
		ajusteApenasCampanhas() {
			if (!this.validate()) return;
			this.vbZerado = false
			this.espaco_macro = []
			this.cuboMovelDermo = false
			this.apenasCampanhas = true
			this.cuboMovelDermoCampanha = false
			// this.busqueDados();
		},
		ajusteVbZerado () {
			if (!this.validate()) return;
			this.vbZerado = true
			this.cuboMovelDermo = false
			this.apenasCampanhas = false
			this.cuboMovelDermoCampanha = false
			// this.busqueDados();
		},	
		ajusteCuboMovelDermo (v) {
			if (!this.validate()) return;
			if (v) {
				this.espaco_macro = ['Cubo','Móvel Dermo']
			} 
			this.vbZerado = false
			this.cuboMovelDermo = true
			this.apenasCampanhas = false
			this.cuboMovelDermoCampanha = false
			// this.busqueDados();
		},
		limpar() {
			this.iniciaFiltro();
		},
		filtrar() {
			console.log(this.cuboMovelDermo)
			if (this.cuboMovelDermo) this.ajusteCuboMovelDermo (true)
			if (!this.validate()) return;
			this.$emit("filtrar");
		},
		validate() {
			return this.$refs.formFiltro.validate();
		},
		resetValidation() {
			try {
				this.$refs.formFiltro.resetValidation();
			} catch (error) {
				//console.log(error)
			}
		},
	},
};
</script>

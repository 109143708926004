var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mt-2 pt-4 px-2",attrs:{"elevation":"1"}},[_c('v-form',{ref:"formFiltro"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-text-field',{attrs:{"label":"Mês","rules":_vm.regra.mes,"required":"","outlined":"","dense":"","type":"month"},model:{value:(_vm.mes),callback:function ($$v) {_vm.mes=$$v},expression:"mes"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-autocomplete',{attrs:{"label":"Filial","items":_vm.lista.filial,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.id_filial.length === 1 && index === 0)?_c('div',{staticStyle:{"width":"70% !important","white-space":"nowrap !important","text-overflow":"ellipsis !important","overflow":"hidden"}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(_vm.id_filial.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.id_filial.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.id_filial),callback:function ($$v) {_vm.id_filial=$$v},expression:"id_filial"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-select',{attrs:{"label":"Bandeira","items":_vm.lista.bandeira,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.bandeira.length === 1 && index === 0)?_c('span',[_vm._v(_vm._s(item.text))]):_vm._e(),(_vm.bandeira.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.bandeira.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.bandeira),callback:function ($$v) {_vm.bandeira=$$v},expression:"bandeira"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-autocomplete',{attrs:{"label":"Fornecedor","items":_vm.lista.fornecedor,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(
									_vm.id_fornecedor.length === 1 && index === 0
								)?_c('div',{staticStyle:{"width":"70% !important","white-space":"nowrap !important","text-overflow":"ellipsis !important","overflow":"hidden"}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(
									_vm.id_fornecedor.length > 1 && index === 0
								)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.id_fornecedor.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.id_fornecedor),callback:function ($$v) {_vm.id_fornecedor=$$v},expression:"id_fornecedor"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-autocomplete',{attrs:{"label":"Espaço","items":_vm.lista.espaco,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
								var item = ref.item;
								var index = ref.index;
return [(_vm.id_espaco.length === 1 && index === 0)?_c('div',{staticStyle:{"width":"70% !important","white-space":"nowrap !important","text-overflow":"ellipsis !important","overflow":"hidden"}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(_vm.id_espaco.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.id_espaco.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.id_espaco),callback:function ($$v) {_vm.id_espaco=$$v},expression:"id_espaco"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-autocomplete',{attrs:{"label":"Espaço Macro","items":_vm.lista.espaco_macro,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
								var item = ref.item;
								var index = ref.index;
return [(
									_vm.espaco_macro.length === 1 && index === 0
								)?_c('div',{staticStyle:{"width":"70% !important","white-space":"nowrap !important","text-overflow":"ellipsis !important","overflow":"hidden"}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(_vm.espaco_macro.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.espaco_macro.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.espaco_macro),callback:function ($$v) {_vm.espaco_macro=$$v},expression:"espaco_macro"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-select',{attrs:{"label":"Cluster","items":_vm.lista.cluster,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
								var item = ref.item;
								var index = ref.index;
return [(_vm.id_cluster.length === 1 && index === 0)?_c('span',[_vm._v(_vm._s(item.text))]):_vm._e(),(_vm.id_cluster.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.id_cluster.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.id_cluster),callback:function ($$v) {_vm.id_cluster=$$v},expression:"id_cluster"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-autocomplete',{attrs:{"label":"Região","items":_vm.lista.regiao,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
								var item = ref.item;
								var index = ref.index;
return [(_vm.regiao.length === 1 && index === 0)?_c('div',{staticStyle:{"width":"70% !important","white-space":"nowrap !important","text-overflow":"ellipsis !important","overflow":"hidden"}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(_vm.regiao.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.regiao.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.regiao),callback:function ($$v) {_vm.regiao=$$v},expression:"regiao"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-select',{attrs:{"label":"Perfil","items":_vm.lista.perfil,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
								var item = ref.item;
								var index = ref.index;
return [(_vm.perfil.length === 1 && index === 0)?_c('span',[_vm._v(_vm._s(item.text))]):_vm._e(),(_vm.perfil.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.perfil.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.perfil),callback:function ($$v) {_vm.perfil=$$v},expression:"perfil"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-select',{attrs:{"label":"Tamanho","items":_vm.lista.tamanho,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
								var item = ref.item;
								var index = ref.index;
return [(_vm.tamanho.length === 1 && index === 0)?_c('span',[_vm._v(_vm._s(item.text))]):_vm._e(),(_vm.tamanho.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.tamanho.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.tamanho),callback:function ($$v) {_vm.tamanho=$$v},expression:"tamanho"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-autocomplete',{attrs:{"label":"Grupo","items":_vm.lista.grupo,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
								var item = ref.item;
								var index = ref.index;
return [(_vm.grupo.length === 1 && index === 0)?_c('div',{staticStyle:{"width":"70% !important","white-space":"nowrap !important","text-overflow":"ellipsis !important","overflow":"hidden"}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(_vm.grupo.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.grupo.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.grupo),callback:function ($$v) {_vm.grupo=$$v},expression:"grupo"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-autocomplete',{attrs:{"label":"Subgrupo","items":_vm.lista.subgrupo,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
								var item = ref.item;
								var index = ref.index;
return [(_vm.subgrupo.length === 1 && index === 0)?_c('div',{staticStyle:{"width":"70% !important","white-space":"nowrap !important","text-overflow":"ellipsis !important","overflow":"hidden"}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(_vm.subgrupo.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.subgrupo.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.subgrupo),callback:function ($$v) {_vm.subgrupo=$$v},expression:"subgrupo"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-autocomplete',{attrs:{"label":"Categoria","items":_vm.lista.categoria,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
								var item = ref.item;
								var index = ref.index;
return [(_vm.categoria.length === 1 && index === 0)?_c('div',{staticStyle:{"width":"70% !important","white-space":"nowrap !important","text-overflow":"ellipsis !important","overflow":"hidden"}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(_vm.categoria.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.categoria.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.categoria),callback:function ($$v) {_vm.categoria=$$v},expression:"categoria"}})],1),_c('v-col',{staticClass:"px-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3"}},[_c('v-autocomplete',{attrs:{"label":"Subcategoria","items":_vm.lista.subcategoria,"multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
								var item = ref.item;
								var index = ref.index;
return [(
									_vm.subcategoria.length === 1 && index === 0
								)?_c('div',{staticStyle:{"width":"70% !important","white-space":"nowrap !important","text-overflow":"ellipsis !important","overflow":"hidden"}},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),(_vm.subcategoria.length > 1 && index === 0)?_c('span',{staticClass:"grey--text caption mr-1"},[_vm._v(_vm._s(_vm.subcategoria.length)+" selecionados")]):_vm._e()]}}]),model:{value:(_vm.subcategoria),callback:function ($$v) {_vm.subcategoria=$$v},expression:"subcategoria"}})],1),_c('v-col',{staticClass:"px-2 ml-auto pb-2",attrs:{"cols":"12","sm":"6","md":"4","lg":"3","xl":"3","align":"end"}},[_c('v-btn',{staticClass:"ml-2 mb-1",attrs:{"color":"primary"},on:{"click":function($event){return _vm.limpar()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter-off")]),_vm._v(" Limpar ")],1),_c('v-btn',{staticClass:"ml-2 mb-1",attrs:{"color":"primary"},on:{"click":function($event){return _vm.filtrar()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter")]),_vm._v(" Filtrar ")],1)],1)],1)],1)],1),_c('v-row',{staticClass:"w-full"},[_c('v-col',{attrs:{"md":"3"}},[_c('div',{on:{"click":_vm.ajusteVbZerado}},[_c('Indicador',{class:{
						active: _vm.vbZerado,
					},attrs:{"titulo":"VB zerado","valor":_vm.vbZerado ? _vm.vbZeradoQuantidade : '',"icone":"mdi-numeric-0-circle","color":"success"}})],1)]),_c('v-col',{attrs:{"md":"3"}},[_c('div',{on:{"click":_vm.ajusteCuboMovelDermo}},[_c('Indicador',{class:{
						active: _vm.cuboMovelDermo,
					},attrs:{"titulo":"Cubo + Movel Dermo","valor":_vm.cuboMovelDermo ? _vm.cuboMovelDermoQuantidade : '',"icone":"mdi-cube","color":"warning"}})],1)]),_c('v-col',{attrs:{"md":"3"}},[_c('div',{on:{"click":_vm.ajusteApenasCampanhas}},[_c('Indicador',{class:{
						active: _vm.apenasCampanhas,
					},attrs:{"titulo":"Apenas Campanhas","valor":_vm.apenasCampanhas ? _vm.apenasCampanhasQuantidade : '',"icone":"mdi-shopping","color":"error"}})],1)]),_c('v-col',{attrs:{"md":"3"}},[_c('div',{on:{"click":_vm.busqueCuboMovelDermoCampanha}},[_c('Indicador',{class:{
						active: _vm.cuboMovelDermoCampanha,
					},attrs:{"titulo":"Campanhas + Cubo + Movel Dermo","valor":_vm.cuboMovelDermoCampanha ? _vm.cuboMovelDermoCampanhaQuantidade : '',"icone":"mdi-animation","color":"error"}})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
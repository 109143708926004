<template>
	<v-container fluid>
		<v-row no-gutters>
			<v-col cols="12" sm="12" md="4" lg="4" xl="4">
				<v-toolbar-title class="ml-2 mt-2">VB</v-toolbar-title>
			</v-col>
			<v-col cols="12" sm="12" md="8" lg="8" xl="8" align="end">
				<v-btn class="ml-2 my-1" color="primary" @click="exportar()">
					<v-icon left>mdi-microsoft-excel</v-icon> Exportar CSV
				</v-btn>
			</v-col>
		</v-row>
		
		<filtro ref="filtro" @filtrar="filtrar"></filtro>
		
		
		<v-card class="my-4 pt-2 px-2" elevation="1">
			<v-data-table
				:headers="headers"
				:items="dados"
				:items-per-page="5"
				:footer-props="{
					'items-per-page-options': [5, 10, 15, 25, 50, 100],
				}"
				:options.sync="pagination"
				:server-items-length="total"
				multi-sort
				class="elevation-0"
			>
			</v-data-table>
		</v-card>
		<v-overlay :value="carregando" :z-index="300">
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
		<v-snackbar
			v-model="alerta"
			:color="alertaColor"
			:timeout="alertaTimeout"
			top
			style="z-index: 400"
		>
			<v-alert :type="alertaColor">
				{{ alertaTexto }}
			</v-alert>
			<v-btn dark text class="float-right" @click="alerta = false">
				Fechar
			</v-btn>
		</v-snackbar>
		<v-dialog v-model="dialogoDownload" width="40%">
			<v-card>
				<v-card-title class="subtitle-1 primary white--text">
					Download do vb em segundo plano
				</v-card-title>
				<v-card-text>
					
					<v-row no-gutters class="mx-2 mt-8" >
						<v-col cols="12" class="px-2">
							Serão baixados {{total}} itens, Para acompanhar voce pode ir a barra lateral de usuário.
						</v-col>
						
					</v-row>
					
				</v-card-text>
				<v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="new-button mr-3 px-4 mb-4"
                        @click="dialogoDownload = false"
                    >
                        <v-icon class="mr-3">mdi-check-circle</v-icon>
                        OK
                    </v-btn>
                </v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import Filtro from "./Filtro.vue";
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
export default {
	components: {
		Filtro,
	},
	data() {
		return {
			carregando: false,
			dialogoDownload: false,
			// dados: [],
			headers: [
				{ text: "Acor.", value: "id_acordo", align: "center" },
				{ text: "Acor. RD", value: "acordo_rd", align: "center" },
				{ text: "Bandeira", value: "bandeira", align: "center" },
				{ text: "ID_JAVA", value: "id_java", align: "center" },
				{ text: "Filial", value: "filial", align: "center" },
				{ text: "CNPJ", value: "cnpj", align: "center" },
				{ text: "Cont. RD", value: "contrato", align: "center" },
				{
					text: "Cod. Cluster",
					value: "codigo_cluster",
					align: "center",
				},
				{ text: "Cluster", value: "cluster", align: "center" },
				{
					text: "Cod. Prod.",
					value: "codigo_produto",
					align: "center",
				},
				{ text: "Produto", value: "produto", align: "center" },
				{ text: "VB Acor.", value: "vb_acordo", align: "center" },
				{ text: "VB Pond.", value: "vb", align: "center" },
				{ text: "Variação", value: "variacao", align: "center" },
				{ text: "Espaço", value: "espaco", align: "center" },
				{ text: "Quant. Prod.", value: "produtos", align: "center" },
				{
					text: "Investimento",
					value: "investimento",
					align: "center",
				},
				{ text: "Mês", value: "mes", align: "center" },
				{
					text: "Cod. Forn.",
					value: "codigo_fornecedor",
					align: "center",
				},
				{ text: "Fornecedor", value: "fornecedor", align: "center" },
				{ text: "Grupo", value: "grupo", align: "center" },
				{ text: "Subgrupo", value: "subgrupo", align: "center" },
				{ text: "Categoria", value: "categoria", align: "center" },
				{
					text: "Subcategoria",
					value: "subcategoria",
					align: "center",
				},
				{
					text: "Mix de lojas",
					value: "vlr_agregado",
					align: "center",
				},
				{ text: "Situação", value: "ativo_inativo", align: "center" },
				{ text: "Status de permanência", value: "status", align: "center" },
			
                {
                    text: "Prod. Edit.",
                    value: "produtos_alterados",
                    align: "center",
                    class: "th-separador   ",
                },
				{ text: "Início do contrato", value: "inicio", align: "center" },
				{ text: "Termino do contrato", value: "termino", align: "center" },
			],
			// pagination: {},
			// total: null,
			primeiraBusca: true,
			alerta: false,
			alertaTexto: "",
			alertaColor: "error",
			alertaTimeout: 3000,
		};
	},
	// provide() {
	// 	return {
	// 		listaCampos: this.listaCampos(),
	// 	};
	// },
	watch: {
		pagination: {
			handler() {
				if (this.primeiraBusca) {
					this.$refs.filtro.iniciaFiltro();

					/* setTimeout(() => {

                        this.filtrar()
                    }, 500) */

					this.primeiraBusca = false;
				} else {
					this.filtrar();
				}
			},
			deep: true,
		},
	},
	computed: {
		...mapFields('relatorio_vb',['total','dados','pagination'])
	},
	methods: {
		...mapActions('relatorio_vb',['iniciaFiltro','busqueDados','busqueTotal','agendeExportar']),
		// async listaCampos() {
		// 	this.carregando = true;

		// 	var filtros = await this.$http().post("/gerencial/filtros", {});

		// 	var filtrosProdutos = await this.$http().post(
		// 		"/gerencial/filtrosProdutos",
		// 		{}
		// 	);

		// 	this.carregando = false;

		// 	return { ...filtros.data, ...filtrosProdutos.data };
		// },
		async filtrar() {
			if (!this.$refs.filtro.validate()) return;
			this.carregando = true;
			await this.busqueDados();
			await this.busqueTotal()
			this.carregando = false;
		},
		async exportar() {
			if (!this.$refs.filtro.validate()) return;
			this.carregando = true;
			// await this.busqueDados();
			await this.busqueTotal()
			await this.agendeExportar();
			this.dialogoDownload = true
			// if (this.$refs.filtro.filtro.cuboMovelDermo)
			// 	this.$refs.filtro.ajusteCuboMovelDermo();

			// var data = { ...this.$refs.filtro.filtro };

			// data.mes = data.mes
			// 	? [data.mes + "-01"]
			// 	: [this.$moment().format("YYYY-MM-01")];

			// var response = await this.$http().post("/gerencial/exportarVb", {
			// 	data: data,
			// });

			// window.location = this.$http("baseURL") + response.data.url;

			this.carregando = false;
		},
	},
};
</script>

<style></style>

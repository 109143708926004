<template>
    <v-form ref="form">
        <v-row class="mt-4">
            <v-col cols="3" class="py-0">
                <DataInput
                    v-model="value.data_inicio"
                    type="date"
                    label="Data de Início"
                    :rules="regra.data_inicio"
                    :min="dataMinima"
                    :first-day-of-week="1"
                    outlined
                    :disabled="planejamentoEdicao"
                    dense
                ></DataInput>
            </v-col>
            <v-col cols="3" class="py-0">
                <DataInput
                    v-model="value.data_fim"
                    type="date"
                    label="Data final"
                    outlined
                    dense
                    :rules="regra.data_fim"
                    :disabled="planejamentoEdicao"

                    :min="dataMinima"
                    :first-day-of-week="1"
                ></DataInput>
            </v-col>
            <v-col cols="3" class="py-0">
                <v-select
                    label="Semana"
                    outlined
                    dense
                    class="input-cinza"
                    v-model="value.semana"
                    :items="listaSemanas"
                    :menu-props="menuProps"
                    @change="changeSemana"
                    multiple
                    :rules="regra.semana"
                    :readonly="planejamentoEdicao"
                    required
                >
                    <template v-slot:selection="{ item, index }">
                        <span v-if="value.semana.length === 1 && index === 0">{{
                            item.text
                        }}</span>
                        <span
                            v-if="value.semana.length > 1 && index === 0"
                            class="grey--text caption mr-1"
                            >{{ value.semana.length }} selecionados</span
                        >
                    </template>
                </v-select>
            </v-col>
            <v-col cols="3" class="py-0">
                <v-autocomplete
                    :label="detalhesCampo('ativacao').nome"
                    class="input-cinza"
                    outlined
                    dense
                    :disabled="planejamentoEdicao"
                    :items="lista.ativacoes"
                    v-model="value.id_ativacao"
                    :menu-props="menuProps"
                    :rules="regra.ativacao"
                    required
                ></v-autocomplete>
            </v-col>

            <v-col cols="3" class="py-0">
                <v-select
                    label="Bandeira"
                    outlined
                    dense
                    class="input-cinza"
                    v-model="value.id_bandeira"
                    :items="lista.bandeira"
                    :menu-props="menuProps"
                    :rules="regra.id_bandeira"
                    required
                ></v-select>
            </v-col>
            <v-col cols="3" class="py-0">
                <v-tooltip top :disabled="value.id_bandeira > 0">
                    <template v-slot:activator="{ on, attrs }">
                        <v-autocomplete
                            label="Categoria"
                            class="input-cinza"
                            outlined
                            v-bind="attrs"
                            v-on="on"
                            :readonly="!value.id_bandeira"
                            dense
                            :items="listaCategoria"
                            v-model="value.id_categoria"
                            :menu-props="menuProps"
                            :rules="regra.categoria"
                            :disabled="planejamentoEdicao"
                            required
                        ></v-autocomplete>
                    </template>
                    <span>Selecione a bandeira primeiro</span>
                </v-tooltip>
            </v-col>
            <v-col v-if="detalhesCampo('device')" cols="3" class="py-0">
                <v-select
                    label="Device"
                    outlined
                    dense
                    class="input-cinza"
                    v-model="value.device"
                    :items="lista.device"
                    :menu-props="menuProps"
                    :rules="regra.device"
                    multiple
                    required
                ></v-select>
            </v-col>
            <v-col cols="3" class="py-0">
                <v-select
                    label="Tipo/Área"
                    outlined
                    dense
                    class="input-cinza"
                    v-model="value.id_area"
                    :items="lista.areas"
                    :menu-props="menuProps"
                    :rules="regra.area"
                    required
                ></v-select>
            </v-col>
            <v-col cols="3" class="py-0">
                <v-autocomplete
                    label="Fornecedor"
                    class="input-cinza"
                    outlined
                    dense
                    :items="lista.fornecedor"
                    v-model="value.id_fornecedor"
                    :menu-props="menuProps"
                    :rules="regra.fornecedor"
                    clearable
                ></v-autocomplete>
            </v-col>
            <v-col v-if="detalhesCampo('acao')" cols="3" class="py-0">
                <v-text-field
                    label="Ação"
                    class="input-cinza"
                    v-model="value.acao"
                    required
                    :rules="regra.acao"
                    outlined
                    dense
                ></v-text-field>
            </v-col>
            <v-col v-if="detalhesCampo('marca')" cols="3" class="py-0">
                <v-tooltip top :disabled="value.id_fornecedor > 0">
                    <template v-slot:activator="{ on, attrs }">
                        <v-autocomplete
                            label="Marca"
                            class="input-cinza"
                            v-bind="attrs"
                            v-on="on"
                            outlined
                            :readonly="!value.id_fornecedor"
                            dense
                            :items="listaMarcas"
                            :loading="loadingMarcas"
                            v-model="value.marca"
                            :menu-props="menuProps"
                            :rules="regra.marca"
                            required
                            multiple
                        ></v-autocomplete>
                    </template>
                    <span>Selecione o fornecedor primeiro</span>
                </v-tooltip>
            </v-col>
            <v-col cols="3" class="py-0">
                <v-autocomplete
                    label="Plano"
                    outlined
                    dense
                    class="input-cinza"
                    v-model="value.plano"
                    :items="lista.planos"
                    :menu-props="menuProps"
                    :rules="areaTrade ? regra.plano : []"
                    required
                ></v-autocomplete>
            </v-col>
            <v-col v-if="detalhesCampo('investimento')" cols="3" class="py-0">
                <vuetify-money
                    label="Investimento"
                    v-model="value.investimento"
                    :options="options"
                    class="input-cinza"
                    outlined
                    dense
                    backgroundColor=""
                ></vuetify-money>
            </v-col>
            <v-col cols="3" class="py-0">
                <v-select
                    label="Validação"
                    outlined
                    dense
                    :disabled="planejamentoEdicao"
                    class="input-cinza"
                    v-model="value.id_status_planejamento"
                    :items="lista.status_planejamento"
                    :menu-props="menuProps"
                    required
                ></v-select>
            </v-col>
            <v-col v-if="detalhesCampo('id_termo')" cols="3" class="py-0">
                <v-autocomplete
                    label="Biblioteca de Termos"
                    class="input-cinza"
                    outlined
                    dense
                    :items="lista.termos"
                    v-model="value.id_termo"
                    :menu-props="menuProps"
                    :rules="regra.termo"
                    :disabled="planejamentoEdicao"
                    required
                ></v-autocomplete>
            </v-col>
        </v-row>
        <v-alert
            v-for="inventario in disponibilidade.inventario"
            :key="inventario.mes"
            :type="inventario.disponivel ? 'success' : 'error'"
            text
        >
            {{
                inventario.disponivel
                    ? 'Período com disponibilidade.'
                    : disponibilidade.extra ||
                      'Sem disponibilidade para o período ou ativação selecionada.'
            }}
            - Período:
            {{ $moment(inventario.periodo.mes).format('MMMM YYYY') }} Semana
            {{ inventario.periodo.semana }}
        </v-alert>
        <v-alert type="success" v-if="disponibilidade.disponivel === true" text>
            Período com disponibilidade.
        </v-alert>
        <v-alert type="info" v-if="disponibilidade.loading === true" text>
            Consultando disponibilidade...
        </v-alert>
    </v-form>
</template>
<script>
import useFiltros from '@/hooks/useFiltros'
import Data from '@/plugins/datas'
export default {
    setup() {
        const { isLoading, isError, isFetching, data, error, refetch } =
            useFiltros('/planejamentoEcommerce/listaCampos')
        return {
            isLoading,
            isError,
            isFetching,
            lista: data,
            error,
            refetch,
        }
    },
    props: ['value'],
    data() {
        return {
            loadingMarcas: false,
            listaMarcas: [],
            mesAtual: this.$moment().format('YYYY-MM'),
            disponibilidade: {},

            menuProps: {
                bottom: true,
                offsetY: true,
                contentClass: 'select-outline',
                transition: 'slide-y-transition',
            },
            regra: {
                mes: [
                    (v) => !!v || 'O período inicial é obrigatório',
                    (v) =>
                        this.validarPeriodoInicial(v) ||
                        'Período inicial inválido',
                ],
                data_inicio: [
                    (v) => !!v || 'O campo data de início é obrigatório',
                    (v) => this.validarPeriodoInicial(v) || 'Data inválida',
                ],
                data_fim: [
                    (v) => !!v || 'O campo data de fim é obrigatório',
                    (v) => this.validarPeriodoFinal(v) || 'Data inválida',
                ],
                semana: [(v) => !!v || 'O campo semana é obrigatório'],
                ativacao: [(v) => !!v || 'O campo ativação é obrigatório'],
                categoria: [(v) => !!v || 'O campo categoria é obrigatório'],
                termo: [
                    (v) => !!v || 'O campo biblioteca de termos é obrigatório',
                ],
                id_bandeira: [(v) => !!v || 'O campo bandeira é obrigatório'],
                acao: [(v) => !!v || 'O campo ação é obrigatório'],
                area: [(v) => !!v || 'O campo área é obrigatório'],
                // if area == trade fornecedor is not required
                fornecedor: [(v) => !!v || 'O campo fornecedor é obrigatório'],
                device: [
                    (v) => !!v || 'O campo device é obrigatório',
                    (v) => v.length > 0 || 'O campo device é obrigatório',
                ],
                plano: [(v) => !!v || 'O campo plano é obrigatório'],
                marca: [
                    (v) => !!v || 'O campo marca é obrigatório',
                    (v) => v.length > 0 || 'O campo marca é obrigatório',
                ],
            },
            options: {
                locale: 'pt-BR',
                prefix: 'R$',
                suffix: '',
                length: 11,
                precision: 2,
            },
        }
    },
    computed: {
        listaSemanas() {
            return this.semanasMes.map((semana) => {
                const text = `${semana.semana}ª Sem. ${this.$moment(
                    semana.mes
                ).format('MMMM')} - ${semana.inicio.format(
                    'DD/MM'
                )} a ${semana.fim.format('DD/MM')}`

                return {
                    value: {
                        semana: semana.semana,
                        mes: semana.mes,
                    },
                    text,
                }
            })
        },
        semanasMes() {
            const primeiroDia = this.$moment().startOf('month')
            const ultimoDia = this.$moment().add(6, 'month').endOf('month')

            return Data.calculaSemanaPeriodo(primeiroDia, ultimoDia)
        },
        semanaAtiva() {
            return {}
        },
        areaTrade() {
            console.log('areaTrade',this.value)
            return this.value.id_area == 7 // Trade
        },
        dataMinima() {
            if (this.semanaAtiva.inicio) {
                // return this.semanaAtiva.inicio.format('YYYY-MM-DD')
            }
            return this.$moment().format('YYYY-MM-DD')
        },
        dataMaxima() {
            if (this.semanaAtiva.fim) {
                return this.semanaAtiva.fim.format('YYYY-MM-DD')
            }
            return 1
        },
        listaCategoria() {
            if (!this.value.id_bandeira) return []
            return this.lista.categorias.filter((categoria) => {
                return categoria.bandeiras.includes(this.value.id_bandeira)
            })
        },
        campos() {
            if (!this.lista) return []

            const id_ativacao = this.value.id_ativacao
            const ativacao = this.lista.ativacoes.find(
                (ativacao) => ativacao.value == id_ativacao
            )

            /// Exception for vitrine-patrocinada
            if (
                ativacao &&
                ativacao.text.startsWith('vitrine-patrocinada') &&
                ativacao.text != 'vitrine-patrocinada-pdp'
            ) {
                return this.lista.campos['vitrine-patrocinada']
            }

            if (ativacao && this.lista.campos[ativacao.text]) {
                return this.lista.campos[ativacao.text]
            } else {
                return this.lista.campos['default']
            }
        },
        planejamentoEdicao() {
            return this.value.id_planejamento ? true : false
        },
    },
    watch: {
        'value.data_inicio': {
            handler(v) {
                if(this.planejamentoEdicao) return
                this.changePeriodo()
                this.checaDisponobilidade()
            },
        },
        'value.data_fim': {
            handler(v) {
                if(this.planejamentoEdicao) return
                this.changePeriodo()
                this.checaDisponobilidade()
            },
        },
        // 'value.semana': {

        // },
        'value.id_bandeira': {
            handler(v) {
                this.checaDisponobilidade()
            },
        },
        'value.id_ativacao': {
            handler(v) {
                this.checaDisponobilidade()
            },
        },
        'value.id_categoria': {
            handler(v) {
                this.checaDisponobilidade()
            },
        },
        'value.id_fornecedor': {
            handler(v) {
                this.getMarcas()
                this.buscaProdutoFornecedor()
            },    
        },
        'value.id_termo': {
            handler(v) {
                this.checaDisponobilidade()
            },
        },
    },
    mounted() {
        this.checaDisponobilidade()
    },
    methods: {
        validarPeriodoInicial(v) {
            console.log(this.value.data_fim)
            if (this.value.data_fim) {
                if (this.$moment(this.value.data_inicio).isSameOrBefore(
                    this.value.data_fim
                )) {
                    return true
                } else {                
                    this.value.data_fim = this.value.data_inicio
                }
            }
            return true
        },
        validarPeriodoFinal(v) {
            if (this.value.data_inicio) {
                if (this.$moment(this.value.data_fim).isSameOrAfter(
                    this.value.data_inicio
                )) {
                    return true
                } else {
                    this.value.data_inicio = this.value.data_fim 
                }
            }
            return true
        },
        async buscaProdutoFornecedor() {
            var response = await this.$http().post("/planejamentoEcommerce/produtos", {
                data: {id_fornecedor: this.value.id_fornecedor},
            });
            this.value.produtosServidor = response.data
        },
        async getMarcas() {
            if (!this.value.id_fornecedor) return

            this.loadingMarcas = true
            const response = await this.$http().get(
                `/planejamentoEcommerce/listaMarcas/${this.value.id_fornecedor}`
            )

            if (
                response.status == 200 &&
                response.data.marcas &&
                response.data.marcas.length > 0
            ) {
                if(!response.data.marcas.some(marca => 'MULTIMARCAS' == marca)){
                    response.data.marcas = [ 'MULTIMARCAS', ...response.data.marcas]
                }
                if(!response.data.marcas.some(marca => 'Serviços' == marca)){
                    response.data.marcas = [ 'Serviços', ...response.data.marcas]
                }
                this.listaMarcas = response.data.marcas
            }
            this.loadingMarcas = false
        },

        changePeriodo() {
            if (this.value.data_inicio && this.value.data_fim) {
                this.value.semana = null
            }
            const semanas = Data.calculaSemanaPeriodo(
                this.value.data_inicio,
                this.value.data_fim
            )

            this.value.semana = semanas.map((semana) => ({
                semana: semana.semana,
                mes: semana.mes,
            }))
        },
        validate() {
            return (
                this.$refs.form.validate() &&
                (this.planejamentoEdicao || this.disponibilidade.inventario?.every((i) => i.disponivel))
            )
        },
        resetValidation() {
            this.disponibilidade = {}
            this.$refs.form.resetValidation()
        },
        detalhesCampo(campo) {
            if (this.lista) {
                return this.campos.find((c) => c.campo == campo)
            }
        },
        changeSemana(v) {
            if (this.value.semana && this.value.semana.length > 0) {
                const semanas = this.value.semana.map((semana) => ({
                    semana: semana.semana,
                    mes: semana.mes,
                }))
                const semanaOrdenada = semanas.sort((a, b) => {
                    if (a.mes > b.mes) {
                        return 1
                    }
                    if (a.mes < b.mes) {
                        return -1
                    }
                    if (a.semana > b.semana) {
                        return 1
                    }
                    if (a.semana < b.semana) {
                        return -1
                    }
                    return 0
                })

                const [primeiraSemana] = semanaOrdenada
                const ultimaSemana = semanaOrdenada[semanaOrdenada.length - 1]
                const dataInicio = this.semanasMes.find(
                    (semana) =>
                        semana.semana == primeiraSemana.semana &&
                        semana.mes == primeiraSemana.mes
                )
                const dataFim = this.semanasMes.find(
                    (semana) =>
                        semana.semana == ultimaSemana.semana &&
                        semana.mes == ultimaSemana.mes
                )
                this.$nextTick(() => {
                    this.value.data_inicio =
                        dataInicio.inicio.format('YYYY-MM-DD')
                    this.value.data_fim = dataFim.fim.format('YYYY-MM-DD')
                    this.changePeriodo()
                })
            }
        },
        async checaDisponobilidade() {
            this.disponibilidade = {}
            if(this.planejamentoEdicao) return

            const validacaoTermo = this.detalhesCampo('id_termo')
                ? !this.value.id_termo
                : false

            // Verifica se todos os campos estão preenchidos
            if (
                !this.value.data_inicio ||
                !this.value.data_fim ||
                !this.value.id_bandeira ||
                !this.value.id_ativacao ||
                !this.value.id_categoria ||
                validacaoTermo
            ) {
                return
            }
            this.disponibilidade.loading = true

            // Monta o objeto para enviar para a API
            const data = {
                semana: this.value.semana,
                id_bandeira: this.value.id_bandeira,
                id_ativacao: this.value.id_ativacao,
                id_categoria: this.value.id_categoria,
                id_planejamento: this.value.id_planejamento,
                id_termo: this.value.id_termo,
                data_inicio: this.value.data_inicio,
                data_fim: this.value.data_fim,
            }

            const response = await this.$http().post(
                '/planejamentoEcommerce/disponibilidade',
                { data }
            )
            if (response.status == 200 && response.data.error == false) {
                this.disponibilidade = response.data
                if (response.data.custo) {
                    this.value.investimento = response.data.custo
                }
            } else {
                this.disponibilidade = {
                    loading: false,
                    extra: 'Falha ao checar disponibilidade',
                }
            }
        },
    },
}
</script>

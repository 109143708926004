<template>
    <v-container fluid>
        <v-card class="px-5 py-3 br-15 w-100" elevation="0">
            <v-row>
                <v-col class="flex-grow-1">
                    <v-toolbar-title class="page-title"
                        >Planejamento</v-toolbar-title
                    >
                </v-col>
                <v-col>
                    <v-text-field
                        class="input-button input-cinza"
                        label="Pesquisar"
                        v-model="pesquisa"
                        outlined
                        dense
                        hide-details
                    >
                        <template>
                            <v-icon slot="append" color="white"
                                >mdi-magnify</v-icon
                            >
                        </template>
                    </v-text-field>
                </v-col>
                <div class="d-flex py-3 px-4">
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                class="new-button px-8"
                                dark
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon left>mdi-microsoft-excel</v-icon>
                                Exportar
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item @click="exportar()">
                                <v-list-item-title>Listagem</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="exportar('device')">
                                <v-list-item-title
                                    >Listagem por device</v-list-item-title
                                >
                            </v-list-item>
                            <v-list-item @click="exportar('ativacoes')">
                                <v-list-item-title>Ativações</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="exportar('categorias')">
                                <v-list-item-title
                                    >Categorias</v-list-item-title
                                >
                            </v-list-item>
                            <v-list-item @click="exportar('fornecedores')">
                                <v-list-item-title
                                    >Fornecedores</v-list-item-title
                                >
                            </v-list-item>
                            <v-list-item @click="exportar('termos')">
                                <v-list-item-title>Termos</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="exportar('skusdotrade')">
                                <v-list-item-title>Sku do trade</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-btn
                        class="ml-5 new-button"
                        color="primary"
                        @click="abrirImportacao()"
                    >
                        <v-icon left>mdi-upload</v-icon> Importar
                    </v-btn>
                    <v-btn
                        class="ml-5 new-button"
                        color="primary"
                        @click="novo()"
                    >
                        <v-icon left>mdi-plus-thick</v-icon> Novo
                    </v-btn>
                </div>
            </v-row>
            <filtro ref="filtro" @filtrar="filtrar" @limpar="limpar"></filtro>
        </v-card>
        <indicadores
            ref="indicadores"
            :indicadores="indicadores"
            :filtro="$refs.filtro"
        ></indicadores>
        <v-card class="px-5 py-5 br-15 w-100 mt-5" elevation="0" >
            <v-data-table
                class="elevation-0 tabela_plan"
                :headers="headerListagem"
                :items="dados"
                :items-per-page="5"
                :footer-props="tableConfig"
                :header-props="{ sortIcon: 'mdi-chevron-down' }"
                :search="pesquisa"
                multi-sort
                v-model="selecionado"
                item-key="id_planejamento"
                show-select
            >
            <template v-slot:[`item.data_criacao`]="{ item }">
                    {{ $moment(item.data_criacao).format('DD/MM/YYYY HH:mm:ss') }}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-menu
                        offset-y
                        min-width="180"
                        content-class=" acordo-menu"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                dark
                                color="#f2f2f2"
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="px-1"
                                style="min-width: unset"
                            >
                                <v-icon color="#CCC">mdi-menu</v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-list dense nav>
                                <v-list-item
                                    link
                                    @click="$refs.formulario.editar(item)"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="grey lighten-1"
                                            >mdi-pencil</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Editar
                                            planejamento</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                    link
                                    @click="$refs.formulario.replicar(item)"
                                >
                                    <v-list-item-icon>
                                        <v-icon color="grey lighten-1"
                                            >mdi-autorenew</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Replicar
                                            planejamento</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                                <!-- Historico -->
                                <v-list-item link  @click="$refs.historico.abrir(item)">
                                    <v-list-item-icon>
                                        <v-icon color="grey lighten-1"
                                            >mdi-history</v-icon
                                        >
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title
                                            >Histórico</v-list-item-title
                                        >
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-menu>
                </template>
                <template v-slot:[`item.mes`]="{ item }">
                    {{ $moment(item.mes).format('MMMM [de] YYYY') }}
                </template>
                <template v-slot:[`item.data_inicio`]="{ item }">
                    {{ $moment(item.data_inicio).format('DD/MM/YYYY') }}
                </template>
                <template v-slot:[`item.data_fim`]="{ item }">
                    {{ $moment(item.data_fim).format('DD/MM/YYYY') }}
                </template>
                <template v-slot:[`item.semana`]="{ item }">
                    Semana {{ item.semana }}
                </template>
                <template v-slot:[`item.projeto`]="{ item }">
                    <v-tooltip top v-if="item.projeto">
                        <template  v-slot:activator="{ on, attrs }">
                            <v-btn
                            depressed
                            v-bind="attrs"
                            v-on="on"
                            @click="copyURL(item.projeto)"
                            >
                            {{ item.projeto ? item.projeto.substr(0,20) + '...'  : '' }}
                            
                            </v-btn>
                        </template>
                        <span >{{ item.projeto }}</span>
                    </v-tooltip>
                    <span v-else>{{ item.projeto }}</span>
                </template> 
                <template v-slot:[`item.link`]="{ item }">
                    <v-tooltip top v-if="item.link">
                        <template  v-slot:activator="{ on, attrs }">
                            <v-btn
                            depressed
                            color="default"
                            v-bind="attrs"
                            v-on="on"
                            @click="copyURL(item.link)"
                            >
                            {{ item.link ? item.link.substr(0,20) + '...'  : '' }}
                         
                            </v-btn>
                        </template>
                        <span >{{ item.link }}</span>
                    </v-tooltip>
                    <span v-else>{{ item.link }}</span>
                </template>
                
            </v-data-table>
            <div class="table-footer-prepend">
                <v-btn
                    color="primary"
                    class="new-button mt-3"
                    v-if="selecionado.length > 0"
                    dark
                    @click="abrirDialogoStatus"
                >
                    Alteração de status
                </v-btn>
            </div>
        </v-card>
        <Formulario ref="formulario" @filtrar="filtrar" />
        <importacao ref="importacao" @filtrar="filtrar"></importacao>
        <v-dialog
            content-class="br-15"
            scrollable
            v-model="dialogoStatus"
            width="600"
        >
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="primary-degrade white--text fix-flex"
                >
                    <v-toolbar-title>Alteração de status </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogoStatus = false"
                    >
                        <v-icon size="20px" color="primary">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-form ref="formStatus">
                    <v-card-text class="pa-5">
                        Por favor, selecione o status que deseja alterar:
                        <v-row>
                            <v-col class="">
                                <v-select
                                    label="Novo status"
                                    outlined
                                    dense
                                    class="input-cinza"
                                    v-model="id_status_planejamento"
                                    :items="lista.status_planejamento"
                                    :menu-props="menuProps"
                                    required
                                    :rules="[(v) => !!v || 'Campo obrigatório']"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-textarea
                                    outlined
                                    v-if="id_status_planejamento == 5"
                                    v-model="observacao_validacao"
                                    class="input-cinza"
                                    dense
                                    rows="3"
                                    label= "Observação Validação *"
                                    :rules="[(v) => !!v || 'Campo obrigatório']"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        * Acordos com status de
                        <span style="color: red">"Cancelado"</span> só poderão
                        ser alterados individualmente.
                    </v-card-text>
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="new-button mr-3 px-4 mb-4"
                        @click="alterarStatus"
                    >
                        <v-icon class="mr-3">mdi-check-circle</v-icon>

                        Confirmar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-overlay :value="carregando" style="z-index: 400">
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
        </v-overlay>
        <historico ref="historico"></historico>
    </v-container> 
</template>
  
<script>
import Formulario from './formulario/formulario.vue'
import indicadores from './Indicadores.vue'
import Filtro from './Filtro.vue'
import importacao from './importacao.vue'
import useFiltros from '@/hooks/useFiltros'
import Historico from './Historico.vue'
import { mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
export default {
    name: 'planejamento',
    setup() {
        const { isLoading, isError, isFetching, data, error, refetch } =
            useFiltros('/planejamentoEcommerce/listaCampos')
        return {
            isLoading,
            isError,
            isFetching,
            lista: data,
            error,
            refetch,
        }
    },
    components: { importacao, Formulario, Filtro, indicadores, Historico },

    data() {
        return {
            dados: [],
            carregando: false,
            // id_status_planejamento: null,
            observacao_validacao: null,
            pesquisa: '',
            selecionado: [],
            dialogoStatus: false,
            indicadores: {
                ativos: 0,
                inativos: 0,
                total: 0,
            },
            headerListagem: [],
            menuProps: {
                bottom: true,
                offsetY: true,
                contentClass: 'select-outline',
                transition: 'slide-y-transition',
            },
            tableConfig: {
               itemsPerPageOptions: [5, 10, 20, 50, 100, -1],
                itemsPerPageText: 'Linhas por páginas',
                ofText: 'de',
                pageText: '{0}-{1} de {2}',
            },
        }
    },
    mounted()  {
      //  this.carregando = true
    //   this.filtrar()
          //    var self = this;
       setTimeout(() => this.filtrar(), 3000);
   //     this.carregando = false
        
        
    },
    watch: {
        mesFiltro() {
            this.filtrar()
        },
    },
    computed: {
        ...mapFields('planejamento_ecommerce',[
            'filtro.periodoInicial',
            'filtro.periodoFinal',
            'filtro.tipoPeriodo',
            'filtro.id_bandeira',
            'filtro.id_planejamento',
            'filtro.semana',
            'filtro.id_ativacao',
            'filtro.device',
            'filtro.id_area',
            'filtro.id_fornecedor',
            'filtro.id_categoria',
            'filtro.plano',
            'filtro.id_status_planejamento',
            'filtro.ativacao',
            'filtro.id_macrofornecedor',
            'filtro.acao'
        ])
    },
    methods: {
        ...mapActions('planejamento_ecommerce',['limparFiltro','saveCache']),
        async copyURL(mytext) {
            try {
                await navigator.clipboard.writeText(mytext);
            } catch($e) {
                console.log('erro')
            }
        },
        headerListagemMonte() {
            const filtro = this.$refs.filtro
            if (!this.lista.campos) return false

            let ativacao = 'default'
            if (this.ativacao) {
                ativacao = this.ativacao[0]
            } 

            let campos
            if (ativacao && this.lista?.campos[ativacao]) {
                campos = this.lista.campos[ativacao]
            } else {
                campos = this.lista.campos['default']
            }
            const camposFinal = campos
                .filter((item) => !(item?.ocultarListagem || false))
                .map((item) => {
                    return {
                        text: item.nome,
                        value: item.campo,
                        align: 'center',
                        class: 'th-separador',
                        width: '100px',
                    }
                })

            this.headerListagem = [
                {
                    text: '',
                    value: 'actions',
                    class: 'th-separador',
                    width: '50px',
                },
                ...camposFinal,
            ]

            // },time)

        },
        abrirImportacao() {
            this.$refs.importacao.abrirImportacao()
        },
        novo() {
            this.$refs.formulario.novo()
        },
        async exportar(tipo = 'listagem') {
            this.carregando = true
            // const filtro = this.$refs.filtro.filtro

            const data = {
                periodoInicial: this.periodoInicial,
                periodoFinal: this.periodoFinal,
                tipoPeriodo: this.tipoPeriodo,
                id_bandeira: this.id_bandeira,
                semana: this.semana,
                id_ativacao: this.id_ativacao,
                device: this.device,
                id_area: this.id_area,
                id_fornecedor: this.id_fornecedor,
                id_categoria: this.id_categoria,
                plano: this.plano,
                id_status_planejamento: this.id_status_planejamento ? this.id_status_planejamento : [],
                ativacao: this.ativacao,
                id_macrofornecedor: this.id_macrofornecedor,
                acao: this.acao,            
                tipo,
                id_planejamento: this.id_planejamento
                    ? [this.id_planejamento]
                    : [],
            }

            if(data.acao && data.acao.length > 0){
                data.acao = [data.acao]
            }

            data.data_inicio = this.periodoInicial
            data.data_fim = this.periodoFinal

            const response = await this.$http().post(
                '/planejamentoEcommerce/exportar',
                {
                    data: data,
                }
            )

            if (response.data.url) {
                window.location = this.$http('baseURL') + response.data.url
            }
            this.carregando = false
        },
        limpar() {
            this.limparFiltro();
            this.filtrar()
        },
        abrirDialogoStatus() {
            this.dialogoStatus = true
            this.$refs.formStatus.reset()
        },
        async alterarStatus() {
            if (!this.$refs.formStatus.validate()) return
            this.carregando = true
            // let realocado = false
            // if (this.id_status_planejamento == 7) {
            //     this.id_status_planejamento == 3
            //     realocado = true
            // }
            const data = {
                // realocado,
                id_status_planejamento: this.id_status_planejamento,
                id_planejamento: this.selecionado.map(
                    (item) => item.id_planejamento
                ),
                observacao_validacao: this.observacao_validacao
            }
            const response = await this.$http().post(
                '/planejamentoEcommerce/alterarStatus',
                {
                    data: data,
                }
            )
            if (response.status == 200) {
                this.$refs.formStatus.reset()
                this.dialogoStatus = false
                this.selecionado = []
                this.filtrar()
            } else {
                this.$dialog.notify.error(
                    response.data?.mensagem || 'Erro ao alterar status',
                    {
                        position: 'top-right',
                        timeout: 5000,
                    }
                )
            }
            this.carregando = false
        },
        async filtrar() {
            this.headerListagemMonte()
            if (!this.$refs.filtro.validate()) return

            this.carregando = true
            // const filtro = this.$refs.filtro.filtro

            const data = {
                periodoInicial: this.periodoInicial,
                periodoFinal: this.periodoFinal,
                tipoPeriodo: this.tipoPeriodo,
                id_bandeira: this.id_bandeira,
                semana: this.semana,
                id_ativacao: this.id_ativacao,
                device: this.device,
                id_area: this.id_area,
                id_fornecedor: this.id_fornecedor,
                id_categoria: this.id_categoria,
                plano: this.plano,
                id_status_planejamento: this.id_status_planejamento ? this.id_status_planejamento : [],
                ativacao: this.ativacao,
                id_macrofornecedor: this.id_macrofornecedor,
                acao: this.acao,                
                id_planejamento: this.id_planejamento
                    ? [this.id_planejamento]
                    : [],
            }
            data.data_inicio = this.periodoInicial
            data.data_fim = this.periodoFinal

            // data.pagination = this.pagination;
            data.busca = this.busca

            data.planograma = this.planograma
            data.statusIndicadores = this.statusIndicadores

            if(data.acao && data.acao.length > 0){
                data.acao = [data.acao]
            }

            const response = await this.$http().post(
                '/planejamentoEcommerce/lista',
                {
                    data: data,
                }
            )
            if (response.status == 200 && response.data.data) {
                const { planejamentos, indicadores } = response.data.data
                this.dados = planejamentos
                this.indicadores = indicadores

                // this.pagination.total = response.data.data.total;
            }
            this.saveCache()
            this.carregando = false
        },
    },
}
</script>
<style>
.tabela_plan td {
    max-width: 200px; 
    min-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    word-wrap:break-word;
    text-overflow: ellipsis; 
}
.tabela_plan td:first-child   {
    max-width: 30px; 
    min-width: 30px;
    /* overflow: hidden;
    white-space:pre;
    white-space:pre-wrap; 
    word-wrap:break-word */
}
.tabela_plan td:first-child + td  {
    max-width: 30px; 
    min-width: 30px;
    /* overflow: hidden;
    white-space:pre;
    white-space:pre-wrap; 
    word-wrap:break-word */
}

</style>  
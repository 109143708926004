import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    planejamento_ecommerce: state.planejamento_ecommerce,
    planejamento_ecommerce_gerencial: state.planejamento_ecommerce_gerencial,
    planejamento_ecommerce_gerencialhp: state.planejamento_ecommerce_gerencialhp,
  })
})

import { auth } from './auth'
import planejamento_ecommerce from './planejamento_ecommerce'
import planejamento_ecommerce_gerencial from './planejamento_ecommerce_gerencial'
import planejamento_ecommerce_gerencialhp from './planejamento_ecommerce_gerencialhp'
import relatorio_vb from './relatorio_vb'

export default new Vuex.Store({
  modules: {
    auth,
    planejamento_ecommerce,
    planejamento_ecommerce_gerencial,
    planejamento_ecommerce_gerencialhp,
    relatorio_vb
  },
  plugins: [vuexLocal.plugin]
})

<template>

<v-card-text class="mt-4">   
    <v-alert
        class="mt-4"
        text
        type="info"
        border="left"
        style="font-size: 12px;"
    >
        <ul>
            <li>
                A base utilizada para importação deve ser
                parecida com base exportada. A coluna
                <strong>id_fornecedor</strong> é
                obrigatória (<strong
                    >e os codigos devem ser identicos aos da base
                    exportada</strong
                >), as demais colunas podem ser excluidas.
            </li>
        </ul>
    </v-alert>
    <v-row no-gutters class="mb-2">
        <v-col cols="12" sm="12" md="6" lg="8" xl="8">
            <v-toolbar-title class="ml-2 mt-2">Usuario: {{ usuario.nome }}</v-toolbar-title>
            
        </v-col>
    </v-row>        
    <v-row no-gutters class="mb-2">
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <v-row no-gutters>
            
            <v-col cols="4">
              <v-btn
                class=" my-1"
                color="primary"                     
                @click="exportar()"   
                >
                <v-icon left>mdi-microsoft-excel</v-icon> Exportar                    
              </v-btn>           
              <v-btn
                  class="ml-2 my-1"
                  color="primary"
                  @click="importar()"
              >
                  <v-icon left>mdi-plus-thick</v-icon> Importar
              </v-btn>
            </v-col>               
            <v-col cols="8"  >   
                <v-file-input
                    label="Arquivo CSV"
                    outlined
                    dense
                    v-model="arquivoCSV"
                    :rules="regra.csv"
                    accept=".csv"
                ></v-file-input>
            </v-col>               
          </v-row>
          <v-data-table
                :headers="headers"
                :items="fornecedores"
                :items-per-page="5"
                :search="busca"
                :footer-props="tableConfig"
                :options.sync="pagination"
                multi-sort
                class="tabela elevation-0"
            >   

          <template v-slot:[`item.acoes`]="{ item }">
            <v-icon
              
              class="mr-2"
              color="error"
              @click="confirmExcluir(item)"
              title="Excluir"
            >
              mdi-delete-forever
            </v-icon>
            </template>
            </v-data-table>
        </v-col>
    </v-row>  
    <v-expand-transition>
        <div v-show="erro">
            <v-row no-gutters class="mb-2">
                <v-col cols="6">
                    <download-csv
                        :data="erros"
                        :labels="errosLabels"
                        name="erros.csv"
                        delimiter=";"
                    >
                        <v-btn color="primary">
                            <v-icon left
                                >mdi-microsoft-excel</v-icon
                            >
                            Exportar Erros
                        </v-btn>
                    </download-csv>
                </v-col>
            </v-row>
            <v-data-table
                :headers="headerErros"
                :items="erros"
                :items-per-page="5"
                :options.sync="paginationErros"
                :footer-props="tableConfig"
                
                class="elevation-0 data-table-2 tabela"
            ></v-data-table>
        </div>
    </v-expand-transition>      
    <v-overlay :value="carregando" :z-index="300">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog content-class="br-15" v-model="dialogoExcluir" width="600">
            <v-card>
                <v-toolbar
                    elevation="0"
                    dark
                    class="error white--text fix-flex"
                >
                    <v-toolbar-title>
                        Confirmar remoção
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        fab
                        width="24px"
                        height="24px"
                        elevation="0"
                        light
                        @click="dialogoExcluir = false"
                    >
                        <v-icon size="18px" color="error">mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="mt-4">
                    Deseja realmente <span style="color: red">remover</span> o fornecedor {{ fornecedorExclusao && fornecedorExclusao.nome }}?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="error"
                        class="new-button mr-3 px-4 mb-2"
                        @click="dialogoExcluir = false"
                    >
                        <v-icon class="mr-3">mdi-close-circle</v-icon>

                        Não, não quero remover
                    </v-btn>
                    <v-btn
                        class="new-button px-4 mb-2 mr-2"
                        color="primary"
                        @click="excluirFornecedor()"
                    >
                        <v-icon class="mr-3">mdi-check-circle</v-icon>
                        Sim, quero remover
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
  </v-card-text>  

</template>

<script>
export default {
    name: 'Fornecedores',
    props: ['usuario'],
    data() {
        return {
            busca: '',
            dialogoExcluir: false,
            headers: [
                { text: 'id', value: 'id_fornecedor', align: 'center', class: "th-separador"},
                { text: 'Nome', value: 'nome', align: 'center', class: "th-separador"},
                { text: 'Ações', value: 'acoes', align: 'center', with: '1%'},
            ],
            fornecedores: [],
            pagination: {}, 
            carregando: false, 
            headerErros: [
				{ text: "Descrição do Erro", value: "msg", align: "left" },
			],
                tableConfig: {
            itemsPerPageOptions: [5,10, 15, 25, 50, 100],
            itemsPerPageText: "Linhas por páginas",
            ofText: "de",
            pageText: "{0}-{1} de {2}",
        },
			erros: [],
			errosLabels: { msg: "Descrição do Erro" },
			erro: false,
			paginationErros: {},
			arquivoCSV: null,
            fornecedorExclusao: null,
			regra: {
				csv: [
					(v) => !!v || "Selecione um arquivo.",
					(v) =>
						(!!v && [".csv", ".CSV"].includes(v.name.substr(-4))) ||
						"O arquivo deve ser do tipo CSV.",
				],
			},
			isCsv: false,
        }
    },
    watch: {
        usuario(newUsuario,odlUsuario) {
            // console.log(newUsuario,odlUsuario)
            this.limpeErros()
            this.buscar()
        }
    },
    methods: {
        confirmExcluir(item){
            this.dialogoExcluir = true
            this.fornecedorExclusao = item
        }, 
        async excluirFornecedor(){
            const data = {
                id_usuario: this.usuario.id_usuario,
                id_fornecedor: this.fornecedorExclusao.id_fornecedor
            }
            this.carregando = true
            const response = await this.$http().post('/fornecedoresporusuario/excluir',{data})

            if(response.status == 200){
                this.$dialog.notify.success("Fornecedor removido com sucesso.", {
                        position: "top-right",
                        timeout: 5000,
                    });
                this.buscar()
                this.dialogoExcluir = false
            }else{
                this.$dialog.notify.error("Erro ao tentar remover fornecedor.", {
                        position: "top-right",
                        timeout: 5000,
                    });            }
            this.carregando = false
        },
        async buscar() {
            this.carregando = true
            this.pagination.page = 1
            let data = {exportar: false,id_usuario:this.usuario.id_usuario}
            var response = await this.$http().post('/fornecedoresporusuario/lista',{data})   
            this.fornecedores = response.data           
            this.carregando = false
        },
        async exportar() {
            this.carregando = true
            this.pagination.page = 1
            let data = {exportar: true,id_usuario:this.usuario.id_usuario}
            var response = await this.$http().post('/fornecedoresporusuario/exportar',{data})   
            window.location = this.$http('baseURL')+response.data.url
            this.carregando = false
        },
        limpeErros() {
            this.headerErros = [
				{ text: "Descrição do Erro", value: "msg", align: "left" },
			]
			this.erros = []
			this.errosLabels = { msg: "Descrição do Erro" }
			this.erro = false
			this.paginationErros = {}
        },
		async importar() {
			this.carregando = true;

            this.limpeErros()
           
			var data = { id_usuario: this.usuario.id_usuario };

			var formData = new FormData();

			formData.append("files", this.arquivoCSV);
			formData.append("data", JSON.stringify(data));

			this.arquivoCSV = null;

			try {
				this.$refs.form.resetValidation();
			} catch (error) {
				//console.log(error)
			}

			var response = await this.$http().post(
				"/fornecedoresporusuario/importar",
				formData
			);

			this.carregando = false;

            this.buscar();
			if (!response.data.sucesso) {
				// this.buscar();
				this.erros = response.data.erros;
				this.pagination.page = 1;
				this.erro = true;
			}
		},

    },
    mounted() {
        this.buscar()
    }                
}
</script>

<style>
.ajusteBotoes {
  display: flex;
  flex-direction: column;
  align-content: space-around;
}
</style>
<template>
<div class="downloadstatusbox" >
    <DownloadProgress v-for="downloadEvent in downloadEvents" :key="downloadEvent.chave" 
        :downloadEvent="downloadEvent">
    </DownloadProgress>
</div>        
</template>

<script>
import DownloadProgress from './DownloadProgress.vue';
import { mapFields } from 'vuex-map-fields'
export default {
    name: 'DownloadStatus',
    components: { DownloadProgress },
    computed: {
        ...mapFields('relatorio_vb', [
            'downloadEvents',
        ]),
    },
    methods: {
        formatarValor(valor) {
            return Number(valor).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
            })
        },
    },
    mouted() {
        console.log(this.downloadEvents)
    }
}
</script>

<style scoped>
.downloadstatusbox {
  
    height: 340px;
    /* background-color: aqua; */
    overflow-x: hidden; /* Hide horizontal scrollbar */
    overflow-y: scroll; /* Add vertical scrollbar */
}
</style>